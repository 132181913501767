import { FC, useState } from 'react';

import { Dropdown, Row, MenuProps, Col } from 'antd';
import { Link } from 'react-router-dom';

import IconQuestion from '@/assets/icons/icon-question-mark.svg?react';
import { TourSteps } from '@/constants';
import useProductTour from '@/hooks/useProductTour';
import paths from '@/routes/paths';
import useProductTourStore from '@/store/useProductTourStore';

const Terms: FC = () => {
  const [open, setOpen] = useState(false);
  const { currentStep, isTourActive, setShowModal } = useProductTourStore();
  const dropdownWrapperRef = useProductTour('helpSection');

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Link target="_blank" to={paths.external.tos}>
          Terms of Service
        </Link>
      ),
      disabled: isTourActive,
    },
    {
      key: '2',
      label: (
        <Link target="_blank" to={paths.external.privacy}>
          Privacy Policy
        </Link>
      ),
      disabled: isTourActive,
    },
    {
      key: '3',
      label: (
        <Link
          to="#"
          onClick={() => {
            if (!isTourActive) {
              setShowModal(true);
            }
          }}
        >
          Product Tour
        </Link>
      ),
      disabled: isTourActive,
    },
    {
      key: '4',
      label: (
        <Link target="_blank" to={paths.external.userguide}>
          User Guide
        </Link>
      ),
      disabled: isTourActive,
    },
    {
      key: '5',
      label: (
        <Link target="_blank" to={paths.external.knowledgeCenter}>
          Knowledge Center
        </Link>
      ),
      disabled: isTourActive,
    },
  ];

  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };

  return (
    <div ref={dropdownWrapperRef}>
      <Dropdown
        menu={{ items }}
        trigger={['click']}
        open={open || (isTourActive && currentStep === TourSteps.HELP_SECTION)}
        onOpenChange={handleOpenChange}
        placement="bottomRight"
        overlayStyle={{ top: '65px' }}
      >
        <Col>
          <Row
            style={{ minWidth: '48px' }}
            className="toolbar-icon"
            align="middle"
            justify="center"
          >
            <IconQuestion />
          </Row>
        </Col>
      </Dropdown>
    </div>
  );
};

const HeaderToolbar: FC = () => {
  return (
    <Row className="border-divide-left" align="middle" justify="center">
      <Terms />
    </Row>
  );
};

export default HeaderToolbar;
