const main = 'https://ratiosim.com';
const paths = {
  landing: '/',
  dashboard: '/dashboard',
  login: '/login',
  register: {
    base: '/create-account',
    contactUs: '/create-account/contact-us',
  },
  passwordRecovery: '/reset-password',
  notFound: '/not-found',
  reactivate: '/reactivate',
  onboarding: {
    base: '/onboarding',
    profile: 'profile',
    organization: 'organization',
    plans: 'plans',
    checkout: 'checkout',
  },
  simulation: {
    base: '/simulations',
    new: '/simulations/new',
    result: '/simulations/result/:resultId',
  },
  plants: {
    base: '/plants',
    create: '/plants/create',
  },
  scenarios: {
    base: '/scenarios',
    create: '/scenarios/create',
  },
  storageSystems: {
    base: '/storage-systems',
    create: '/storage-systems/create',
  },
  organization: {
    base: '/organization',
    settings: '/organization/settings',
    payments: '/organization/payments',
    access: '/organization/access',
    user: {
      management: '/organization/users',
      create: '/organization/users/new/',
    },
  },
  user: {
    base: '/user',
    changePassword: '/user/change-password',
  },
  external: {
    landing: `${main}/`,
    support: `${main}/support`,
    sales: `${main}/sales`,
    tos: `${main}/terms-of-use`,
    privacy: `${main}/privacy-policy`,
    cookie: `${main}/cookie-policy`,
    userAgreement: `${main}/user-agreement`,
    register: `${main}/get-started`,
    features: `${main}/features`,
    pricing: `${main}/pricing`,
    enterprise: `${main}/enterprise`,
    enterpriseContact: `${main}/enterprise`,
    userguide: 'https://docs.ratiosim.com/usage-guide/a.-getting-started',
    knowledgeCenter: 'https://docs.ratiosim.com/',
  },
};

export const ONBOARDING_PROFILE =
  paths.onboarding.base + '/' + paths.onboarding.profile;
export const ONBOARDING_ORGANIZATON =
  paths.onboarding.base + '/' + paths.onboarding.organization;
export const ONBOARDING_PLANS =
  paths.onboarding.base + '/' + paths.onboarding.plans;
export const ONBOARDING_CHECKOUT =
  ONBOARDING_PLANS + '/' + paths.onboarding.checkout;

export default paths;
